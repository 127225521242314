import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'

import PointLIstStoreModule from './storeModule'

export default function () {
  const APP_STORE_MODULE_NAME = 'app-exchange-list'

  const toast = useToast()
  const makeToast = (variant, title, text) => toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      variant,
      icon: 'AlertTriangleIcon',
    },
  })
  const initialSearchParams = ref({})
  const currentPage = ref(parseInt(router.history.current.query.page, 10) || 1)
  const searchParams = computed(() => {
    const routerQuery = router.history.current.query
    return {
      // page: parseInt(routerQuery.page, 10) || 1,
      page: currentPage.value,
      size: parseInt(routerQuery.size, 10) || 20,
      total: parseInt(routerQuery.total, 10) || 0,
      ...initialSearchParams.value,
    }
  })
  const syncronizeParamsWithUrl = () => {
    const routerQuery = router.history.current.query
    initialSearchParams.value = Object
      .keys(routerQuery)
      .reduce((previoutValue, currentValue) => {
        if (['page', 'size', 'total'].includes(currentValue)) {
          return previoutValue
        }
        if (currentValue === null || currentValue === '') {
          return previoutValue
        }
        if (typeof routerQuery[currentValue] === 'string') {
          return {
            ...previoutValue,
            [currentValue]: [routerQuery[currentValue]],
          }
        }
        return {
          ...previoutValue,
          [currentValue]: routerQuery[currentValue],
        }
      },
      {})
  }

  const replaceRouterQuery = () => {
    router.replace({
      query: { ...searchParams.value },
    }).catch(() => {})
  }

  const categoryOptions = [
    {
      label: '종류',
      value: 'STATE',
      paramName: 'state',
      format: 'checkbox',
      options: [
        { text: 'ADDITION', value: 'ADDITION' },
        { text: 'EXCHANGE', value: 'EXCHANGE' },
        { text: 'CANCEL', value: 'CANCEL' },
        { text: 'USAGE', value: 'USAGE' },
        { text: 'ADDITION_EXPECTED', value: 'ADDITION_EXPECTED' },
        { text: 'INACTIVE', value: 'INACTIVE' },
        { text: 'PENDING', value: 'PENDING' },
      ],
    },
    {
      label: 'INF_IDX',
      value: 'INF_IDX',
      paramName: 'inf_idx',
    },
    {
      label: 'INF_NAME',
      value: 'INF_NAME',
      paramName: 'inf_name',
    },
    {
      label: 'INF_PHONE',
      value: 'INF_PHONE',
      paramName: 'inf_phone',
    },
    {
      label: '생성일자',
      value: 'CREATED_AT',
      paramName: 'created_at',
      format: 'date',
    },
  ]

  const tableColumns = [
    { key: 'idx' },
    { key: 'inf_idx', label: 'inf_idx' },
    { key: 'ssn_name', label: '이름' },
    { key: 'ssn', label: '주민번호' },
    { key: 'tax', label: '소득세' },
    { key: 'apply_point', label: '금액' },
    { key: 'reason', label: '내용' },
    { key: 'addition_expected_date', label: '적립 예정일', formatter: val => (moment(val).isValid() ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null) },
    { key: 'created_at', label: '생성일', formatter: val => (moment(val).isValid() ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null) },
    { key: 'state', label: '종류' },
  ]
  const items = ref([])
  const totalRows = ref(parseInt(searchParams.value.total, 10))
  const perPage = ref(parseInt(searchParams.value.size, 10) ?? 20)
  // const currentPage = computed(() => parseInt(searchParams.value.page, 10) || 1)
  const isBusy = ref(false)

  const fetchItems = () => {
    isBusy.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/fetchPointList`, searchParams.value)
      .then(response => {
        const { page_info, data } = response.data
        items.value = data
        totalRows.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
      })
      .finally(() => {
        isBusy.value = false
        replaceRouterQuery()
      })
  }

  const shortcutSearch = params => {
    initialSearchParams.value = {
      ...params,
    }
    router.replace({
      query: { ...searchParams.value, page: 1, size: 20 },
    }).catch(() => {})
    fetchItems()
  }

  const search = params => {
    initialSearchParams.value = {
      ...initialSearchParams.value,
      ...params,
    }
    currentPage.value = 1
    router.replace({
      query: { ...searchParams.value, page: currentPage.value },
    }).catch(() => {})
    fetchItems()
  }

  const changePage = params => {
    currentPage.value = params.page
    router.replace({
      query: { ...searchParams.value, ...params },
    }).catch(() => {})
    fetchItems()
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, PointLIstStoreModule)
    syncronizeParamsWithUrl()
    fetchItems()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  const downloadFile = (url, name) => {
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = url
    if (name) {
      tempLink.setAttribute('download', name)
    } else {
      tempLink.setAttribute('download', '')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  }

  const isDownloadingExcelPoint = ref(false)
  const downloadExcelPoint = () => {
    isDownloadingExcelPoint.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/excelPoint`, searchParams.value)
      .then(() => {
        makeToast('primary', '엑셀 변환 완료', '이메일을 확인하십시오. 몇 분의 시간이 소요될 수 있습니다.')
      })
      .catch(() => {
        makeToast('danger', '엑셀 변환에 실패 했습니다')
      })
      .finally(() => {
        isDownloadingExcelPoint.value = false
      })
  }

  const downloadExcelChangePoint = () => {
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/excelChangePoint`, searchParams.value)
      .then(response => {
        const fileName = `point_info_${moment().format('YYYYMMDDHHmmss')}.xlsx`
        const file = new File([response.data], fileName, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(file)
        downloadFile(url, fileName)
      })
      .catch(() => {
        makeToast('danger', '엑셀 변환에 실패 했습니다')
      })
      .finally(() => {
        fetchItems()
      })
  }

  return {
    searchParams,
    initialSearchParams,
    categoryOptions,
    tableColumns,
    items,
    totalRows,
    perPage,
    currentPage,
    isBusy,

    fetchItems,
    search,
    changePage,

    downloadExcelPoint,
    shortcutSearch,
    downloadExcelChangePoint,

    isDownloadingExcelPoint,
  }
}
