import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 환불 내역 리스트
    fetchPointList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/point-point/list/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    excelPoint(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/point-point/excel/`,
            params,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    excelChangePoint(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/point-point/excel-change-point/`,
            params,
            { responseType: 'blob' },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
