<template>
  <div>
    <tagby-filter
      :categoryOptions="categoryOptions"
      :initial-search-params="initialSearchParams"
      @search="search"
    >
      <template #shortcut>
        <shortcut @reload="shortcutSearch" />
      </template>
      <template #buttons>
        <b-button
          variant="outline-success"
          class="mr-1"
          :disabled="isDownloadingExcelPoint"
          @click="downloadExcelPoint"
        >
          <b-spinner
            v-if="isDownloadingExcelPoint"
            small
          />
          <span class="align-middle">포인트 현황 엑셀 다운로드</span>
        </b-button>
        <b-button
          variant="outline-success"
          class="mr-1"
          @click="downloadExcelChangePoint"
        >
          <span class="align-middle">포인트 전환자 엑셀 다운로드</span>
        </b-button>
      </template>
    </tagby-filter>
    <tagby-list
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(idx)="data">
        {{ data.value }}
      </template>

      <template #cell(inf_idx)="data">
        <b-link
          :to="{ name: 'member-inf-detail', params: { idx: resolveInfIdx(data.item) } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ resolveInfIdx(data.item) }}
        </b-link>
      </template>

      <template #cell(ssn_name)="data">
        {{ resolveSsnName(data.item) }}
      </template>

      <template #cell(ssn)="data">
        {{ resolveSsn(data.item) }}
      </template>

      <template #cell(deposit)="data">
        {{ resolveDeposit(data.item) }}
      </template>

      <template #cell(addition_expected_date)="data">
        <div style="max-width: 90px;">
          {{ data.value }}
        </div>
      </template>

      <template #cell(created_at)="data">
        <div style="max-width: 90px;">
          {{ data.value }}
        </div>
      </template>
    </tagby-list>
  </div>
</template>

<script>
import {
  BLink,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import PointListViewModel from './viewModel'
import TagbyFilter from '@/components/TagbyFilter.vue'
import TagbyList from '@/components/TagbyList.vue'
import Shortcut from './components/Shortcut.vue'

export default {
  components: {
    BLink,
    BButton,
    BSpinner,
    TagbyFilter,
    TagbyList,
    Shortcut,
  },
  setup() {
    const resolveInfIdx = item => item.influencer?.idx
    const resolveSsnName = item => item.influencer?.ssn?.name
    const resolveSsn = item => item.influencer?.ssn?.ssn
    const resolveDeposit = item => item.deposit ?? 0

    return {
      resolveInfIdx,
      resolveSsnName,
      resolveSsn,
      resolveDeposit,
      ...PointListViewModel(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
