<template>
  <b-row class="w-100 mb-1">
    <b-col class="d-flex">
      <b-badge
        pill
        variant="primary"
        class="mr-1"
        href="#"
        @click="$emit('reload', {created_at: [thisMonth]})"
      >
        이번 달
      </b-badge>
      <b-badge
        pill
        variant="warning"
        class="mr-1"
        href="#"
        @click="$emit('reload', {created_at: [prevMonth]})"
      >
        저번 달
      </b-badge>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
  },
  emits: [
    'reload',
  ],
  setup() {
    const startMonth = moment().startOf('month')
    const endMonth = moment().endOf('month')
    const thisMonth = `${startMonth.utc().format('YYYY-MM-DD HH:mm:ss')} to ${endMonth.utc().format('YYYY-MM-DD HH:mm:ss')}`

    const prevStartMonth = moment().subtract(1, 'months').startOf('month')
    const prevEndMonth = moment().subtract(1, 'months').endOf('month')
    const prevMonth = `${prevStartMonth.utc().format('YYYY-MM-DD HH:mm:ss')} to ${prevEndMonth.utc().format('YYYY-MM-DD HH:mm:ss')}`
    // console.log(thisMonth)
    return {
      thisMonth,
      prevMonth,
    }
  },
}
</script>
